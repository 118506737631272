import { ExternalLinkIcon } from "lucide-react";
import React from "react";

const FixedComponent = ({ contentWrapperRef, isAtEnd, data, newData }) => {
  return (
    <div
      ref={contentWrapperRef}
      className={`px-2  md:px-36 ${
        isAtEnd
          ? "md:absolute md:right-0 md:bottom-0 md:py-32 py-8"
          : "fixed md:right-0"
      } `}
    >
      <div className="border border-[#000000]/10">
        {data.map((ele, i) => {
          return (
            <div key={i} className="flex flex-row">
              <div className="py-2 px-3 w-[50%] bg-[#FFFBED] text-sm font-rubik font-normal border border-[#000000]/10">
                {ele.heading}
              </div>
              <div
                className={
                  ele.desc === "1%" ||
                  ele.desc === "20%" ||
                  ele.desc === "$100,000"
                    ? "text-left py-2 px-3 w-[50%] text-sm font-rubik font-normal border border-[#000000]/10"
                    : "py-2 px-3 w-[50%] text-sm font-rubik font-normal border border-[#000000]/10"
                }
              >
                {ele.desc}
              </div>
            </div>
          );
        })}
      </div>

      <div className="text-base text-black font-rubik font-semibold mt-4">
        FUND LINKS
      </div>

      <div className="mt-4 border border-[#000000]/10 rounded-lg overflow-hidden">
        {newData.map((ele, i) => {
          return (
            <>
              {ele?.link && ele.imageUrl == "" ? (
                <a
                  href={ele.link}
                  target="_blank"
                  className="flex flex-row items-center  py-3 px-3 cursor-pointer hover:bg-[#FFC907]/80 transition-all duration-150 ease-linear "
                >
                  <ExternalLinkIcon className="h-5 w-5 text-zinc-700 " />
                  <div className="ml-3 text-sm text-[#000000]/80 font-rubik ">
                    {ele.name}
                  </div>
                </a>
              ) : (
                <a
                  key={i}
                  href={ele.link}
                  target="_blank"
                  className="flex  flex-row items-center border-b border-[#000000]/10 py-3 px-3 cursor-pointer hover:bg-[#FFC907]/80 transition-all duration-150 ease-linear "
                >
                  <img src={ele.imageUrl} alt="Pdf" />
                  <div className="ml-3 text-sm text-[#000000]/80 font-rubik ">
                    {ele.name}
                  </div>
                </a>
              )}
            </>
          );
        })}
      </div>
      <div className="mt-8">
        <button className="w-full py-3 px-12 bg-[#FFC907] text-[#000000]/80 text-base font-rubik font-semibold rounded-lg">
          Apply to Start Investing
        </button>
      </div>
      <div className="text-base font-rubik text-[#000000]/80 mt-3">
        Still have questions?{" "}
        <a
          href="https://cal.com/secondstreet"
          target="_blank"
          rel="noreferrer noopener"
          className="font-semibold border-b border-[#000000]/80"
        >
          Book a Call Now
        </a>
      </div>
    </div>
  );
};

export default FixedComponent;
