import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import animation from "../common/animation";
import StrategyNavbar from "../Navbar/StrategyNavbar";

import GetStarted from "./GetStarted";
// import Faq from "./Faq";
// import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";

import Pdf from "../assests/pdf.svg";

import FixedComponent from "./FixedComponent";
import FooterDisclaimer from "./FooterDiscliamer";
import FirstDisclaimer from "./FirstDisclaimer";
import LivePerformace from "./LivePerformance";
import StrategyDescription from "./StrategyDescription";
import BacktestedPerformance from "./BacktestedPerformace";
import WhyAlpha from "./WhyAlpha";
import OtherDiscription from "./OtherDescription";
import ContactUsForm from "../Footer/ContactUsForm";
import RiskManagement from "../common/RiskManagement";
import Backtested from "../common/Backtested";
import Statistics from "../common/Statistics";

const data = [
  {
    heading: "Management Fee",
    desc: "1%",
  },
  {
    heading: "Performance Fee",
    desc: "20%",
  },
  {
    heading: "Min. Investment",
    desc: "$100,000",
  },
  {
    heading: "Redemptions",
    desc: "Monthly with 10 days notice",
  },
  {
    heading: "IRA Eligible",
    desc: "Yes",
  },
  {
    heading: "Investor Type",
    desc: "Accredited",
  },
  {
    heading: "Investor Eligibility",
    desc: "Global",
  },
];

const newData = [
  
  {
    imageUrl: Pdf,
    link:"https://drive.google.com/file/d/1N9-2OkCMb8eKAOTXq7eXERyp_7Qaqr4M/view",
    name: "Investment - Indian Markets. Presentation",
  },
  {
    imageUrl: Pdf,
    link:"https://drive.google.com/file/d/1jN8eCBCDaB5bHLX6eVFxa9LFGv1iOGmv/view",
    name: "How to Invest",
  },
  {
    imageUrl: Pdf,
    link:"https://drive.google.com/file/d/1TxiOR3sD-JW4Of3rZW9maENhwSvFUMb1/view",
    name: "Alpha-50 Factsheet",
  },
  {
    imageUrl: "",
    link:"https://dashboard.secondstreet.fund",
    name: "Hstorical Performance Dashboard",
  },
  {
    imageUrl: "",
    link:"https://tr.ee/hUy1O-54C2",
    name: "Contact - Satyam Bhasin",
  }
];

const backtested = [
  {
    imageUrl: Pdf,
    name: "Tear Sheet",
  },
  {
    imageUrl: Pdf,
    name: "Presentation",
  },
  {
    imageUrl: Pdf,
    name: "Investment Memo",
  },
];

const Alpha50 = () => {
  const sidebarRef = useRef(null);
  const contentWrapperRef = useRef(null);

  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const contentWrapper = contentWrapperRef.current;

      if (sidebar && contentWrapper) {
        const scrollTop = window.scrollY;

        if (scrollTop >= 2300) {
          setIsAtEnd(true);
        } else {
          setIsAtEnd(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);

  const [liveData, setLiveData] = useState();
  const getData = () => {
    axios
      .get("https://server.sypto.xyz/sypto/api/alpha-vs-nifty-charts/alpha-50")
      .then((res) => {
        setLiveData(res.data.values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // chart data
  const alphaFiftyPerformceAccountValue = liveData
    ? liveData.map((item) => [item[0], item[1], item[4]])
    : [];
  const chartData =
    alphaFiftyPerformceAccountValue &&
    alphaFiftyPerformceAccountValue.map((innerArray, index) =>
      innerArray.map((value, columnIndex) => {
        // Skip the first column
        if (columnIndex === 0 || typeof value !== "string") {
          return value;
        }

        // Remove the leading rupee sign and commas, then convert to a number
        return parseFloat(value.replace(/^₹|,/g, ""));
      })
    );

  // table data
  const newDataAlpha = liveData
    ? liveData.map((item) => [item[0], item[10], item[11]])
    : [];

  console.log("new", newDataAlpha);
  const dataArray = newDataAlpha ? newDataAlpha : [];

  const alpha50MonthlyReturns = [dataArray[0]]; // Add the header to the new array

  // Create a Set to keep track of unique months
  const uniqueMonthsSet = new Set();

  // Iterate through the original array starting from index 1 (skipping the header)
  for (let i = 1; i < dataArray.length; i++) {
    const currentDate = dataArray[i][0]; // assuming this is in "DD/MM/YYYY" format

    // Ensure that currentDate is a valid string
    if (currentDate && typeof currentDate === "string") {
      const dateParts = currentDate.split("/"); // Splitting "DD/MM/YYYY" into an array
      if (dateParts.length === 3) {
        const month = dateParts[1]; // Get month part
        const year = dateParts[2]; // Get year part
        const currentMonthKey = `${month}/${year}`; // Construct the month/year key

        // Check if the current month is not already in the uniqueMonthsSet and the data is not empty or undefined
        if (
          !uniqueMonthsSet.has(currentMonthKey) &&
          dataArray[i].every((value) => value !== undefined && value !== "")
        ) {
          alpha50MonthlyReturns.push(dataArray[i]);
          uniqueMonthsSet.add(currentMonthKey);
        }
      } else {
        console.warn("Unexpected date format:", currentDate);
      }
    }
  }

  const [show, setShow] = useState(false);

  return (
    <div>
      <StrategyNavbar />
      <div className=" relative  flex flex-col md:flex-row justify-between pt-24 md:pt-32 pb-8 md:pb-32 px-4 md:px-36">
        <div className="w-full md:w-[70%] md:min-h-full ">
          <div className=" text-xl md:text-[32px] md:leading-[62px] font-rubik font-bold">
            Alpha 50 Strategy
          </div>
          <div className="text-sm font-rubik text-[#000000]/70">
            Momentum Strategy to beat Nifty 50 with its own constituents.
          </div>
          <div className="pt-12 font-rubik text-[18px] opacity-70">
            Alpha 50 selects 10 stocks from the NIFTY 50 based on quantitative
            momentum, strength, and relative value metrics. The strategy targets
            consistent alpha with the objective of outperforming the benchmark
            index over three-year periods. The primary focus is on risk-adjusted
            returns and long-term wealth creation.
          </div>
          <StrategyDescription />
          <FirstDisclaimer />
          <LivePerformace
            monthlyReturns={alpha50MonthlyReturns ? alpha50MonthlyReturns : []}
            performaceData={chartData && chartData}
            tabName="alpha-50"
            titleOne="Alpha 50"
            titleTwo="Nifty 50"
          />
          <RiskManagement />
          <BacktestedPerformance backtested={backtested} />
          <Backtested page="ALPHA 50" />
          <Statistics page="ALPHA 50" />
          {/* <WhyAlpha title="50" /> */}
          {/* <OtherDiscription /> */}
        </div>
        <div ref={sidebarRef} className="sidebar min-h-full w-full md:w-[40%]">
          <FixedComponent
            contentWrapperRef={contentWrapperRef}
            isAtEnd={isAtEnd}
            data={data}
            newData={newData}
          />
        </div>
      </div>
      <FooterDisclaimer />
      <GetStarted />
      {/*  <Faq /> <UpdateBox />*/}

      <Footer setShow={setShow} />
      {show === true ? <ContactUsForm setShow={setShow} show={show} /> : null}
    </div>
  );
};

export default Alpha50;
