import React, { useEffect, useRef, useState } from "react";
import animation from "../common/animation";
import StrategyNavbar from "../Navbar/StrategyNavbar";

import GetStarted from "./GetStarted";
// import Faq from "./Faq";
import UpdateBox from "../Footer/UpadteBox";
import Footer from "../Footer/Footer";

import Pdf from "../assests/pdf.svg";

import FixedComponent from "./FixedComponent";
import FooterDisclaimer from "./FooterDiscliamer";
import FirstDisclaimer from "./FirstDisclaimer";
import LivePerformace from "./LivePerformance";
import StrategyDescription from "./StrategyDescription";
import BacktestedPerformance from "./BacktestedPerformace";
import WhyAlpha from "./WhyAlpha";
import OtherDiscription from "./OtherDescription";
import ContactUsForm from "../Footer/ContactUsForm";

const data = [
  {
    heading: "Management Fee",
    desc: "1%",
  },
  {
    heading: "Performance Fee",
    desc: "20%",
  },
  {
    heading: "Min. Investment",
    desc: "$100,000",
  },
  {
    heading: "Redemptions",
    desc: "Monthly with 10 days notice",
  },
  {
    heading: "IRA Eligible",
    desc: "Yes",
  },
  {
    heading: "Investor Type",
    desc: "Accredited",
  },
  {
    heading: "Investor Eligibility",
    desc: "Global",
  },
];

const newData = [
  {
    imageUrl: Pdf,
    link: "https://drive.google.com/file/d/1aYV6MlfPoGoDS5VUL-c0XKZuH_IZ4rYI/view",
    name: "Investment - US Markets. Presentation",
  },
  {
    imageUrl: Pdf,
    link: "https://drive.google.com/file/d/1jN8eCBCDaB5bHLX6eVFxa9LFGv1iOGmv/view",
    name: "How to Invest",
  },
  {
    imageUrl: "",
    link: "https://dashboard.secondstreet.fund",
    name: "Hstorical Performance Dashboard",
  },
  {
    imageUrl: "",
    link: "https://tr.ee/hUy1O-54C2",
    name: "Contact - Satyam Bhasin",
  },
];

const backtested = [
  {
    imageUrl: Pdf,
    name: "Tear Sheet",
  },
  {
    imageUrl: Pdf,
    name: "Presentation",
  },
  {
    imageUrl: Pdf,
    name: "Investment Memo",
  },
];

const UsaOptions = () => {
  const sidebarRef = useRef(null);
  const contentWrapperRef = useRef(null);

  const [isAtEnd, setIsAtEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = sidebarRef.current;
      const contentWrapper = contentWrapperRef.current;

      if (sidebar && contentWrapper) {
        const scrollTop = window.scrollY;

        if (scrollTop >= 850) {
          setIsAtEnd(true);
        } else {
          setIsAtEnd(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    animation.afterCallback(
      new IntersectionObserver(
        animation.instersectioOberserverCallback,
        animation.rootMargin
      )
    );
  }, []);
  const [show, setShow] = useState(false);
  let monthlyReturns = [];
  let chartData = [];
  return (
    <div>
      <StrategyNavbar />
      <div className=" relative  flex flex-col md:flex-row justify-between pt-24 md:pt-32 pb-8 md:pb-32 px-4 md:px-36">
        <div className="w-full md:w-[75%] md:min-h-full ">
          <div className=" text-xl md:text-[32px] md:leading-[62px] font-rubik font-bold">
            USA Options
          </div>
          <StrategyDescription />
          <FirstDisclaimer />
          <LivePerformace
            monthlyReturns={monthlyReturns}
            performaceData={chartData && chartData}
          />
          <BacktestedPerformance backtested={backtested} />
          {/* <WhyAlpha title="Options" /> */}
          {/* <OtherDiscription /> */}
        </div>
        <div ref={sidebarRef} className="sidebar min-h-full w-full md:w-[40%]">
          <FixedComponent
            contentWrapperRef={contentWrapperRef}
            isAtEnd={isAtEnd}
            data={data}
            newData={newData}
          />
        </div>
      </div>
      <FooterDisclaimer />
      <GetStarted />
      {/*  <Faq />*/}
      <UpdateBox />
      <Footer setShow={setShow} />
      {show === true ? <ContactUsForm setShow={setShow} show={show} /> : null}
    </div>
  );
};

export default UsaOptions;
